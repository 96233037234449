import { useEffect, useState } from "react";
import { images } from "./utils/imagesToLoad";
import HomePage from "./pages/HomePage";
import Loader from "./components/Loader/Loader";
import $ from 'jquery';
import './App.scss';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loadImage = (image) => {
    return new Promise((resolve, reject) => {
      const newImage = new Image();
      newImage.src = image;
      newImage.onload = () => {
        resolve(image);
      }
      newImage.onerror = (err) => reject(err);
    });
  };
  useEffect(() => {
    if (isLoading) {
      $('body').addClass("ovf-hidden");
    }
    Promise
      .all(images.map((image) => loadImage(image)))
      .then(() => setIsLoading(false))
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  useEffect(() => {
    if (!isLoading) {
      $('body').removeClass("ovf-hidden");
    }
  }, [isLoading]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Loader isLoading={isLoading} />
      <HomePage />
    </div>
  );
}

export default App;

import React, { useEffect, useRef, useState } from "react";
import classes from './JoinToday.module.scss';
import circle from '../../assets/img/join_circle.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const JoinToday = () => {
    const circleRef = useRef(null);
    const [ check_01, setCheck_01 ] = useState(false);
    const [ check_02, setCheck_02 ] = useState(false);
    const [ check_03, setCheck_03 ] = useState(false);
    const [ check_04, setCheck_04 ] = useState(false);
    const [ check_05, setCheck_05 ] = useState(false);
    const [ check_06, setCheck_06 ] = useState(false);
    const [ check_07, setCheck_07 ] = useState(false);
    const [ check_08, setCheck_08 ] = useState(false);
    const [ check_09, setCheck_09 ] = useState(false);
    const [ check_10, setCheck_10 ] = useState(false);
    const [ check_11, setCheck_11 ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ question_01, setQuestion_01 ] = useState('');
    const [ question_02, setQuestion_02 ] = useState('');
    const setQuestion_01_func = () => setCheck_01(value => !value);
    const setQuestion_02_func = () => setCheck_02(value => !value);
    const setQuestion_03_func = () => setCheck_03(value => !value);
    const setQuestion_04_func = () => setCheck_04(value => !value);
    const setQuestion_05_func = () => setCheck_05(value => !value);
    const setQuestion_06_func = () => setCheck_06(value => !value);
    const setQuestion_07_func = () => setCheck_07(value => !value);
    const setQuestion_08_func = () => setCheck_08(value => !value);
    const setQuestion_09_func = () => setCheck_09(value => !value);
    const setQuestion_10_func = () => setCheck_10(value => !value);
    const setQuestion_11_func = () => setCheck_11(value => !value);
    const checks = [
        {
            state: check_01,
            action: setQuestion_01_func,
            text: 'reviews written',
        },
        {
            state: check_02,
            action: setQuestion_02_func,
            text: 'surveys conducted',
        },
        {
            state: check_03,
            action: setQuestion_03_func,
            text: 'product tested',
        },
        {
            state: check_04,
            action: setQuestion_04_func,
            text: 'game tested',
        },
        {
            state: check_05,
            action: setQuestion_05_func,
            text: 'likes boosted',
        },
        {
            state: check_06,
            action: setQuestion_06_func,
            text: 'app downloaded',
        },
        {
            state: check_07,
            action: setQuestion_07_func,
            text: 'data entry',
        },
        {
            state: check_08,
            action: setQuestion_08_func,
            text: 'audio transcription',
        },
        {
            state: check_09,
            action: setQuestion_09_func,
            text: 'videos watched',
        },
        {
            state: check_10,
            action: setQuestion_10_func,
            text: 'email correspondence',
        },
        {
            state: check_11,
            action: setQuestion_11_func,
            text: 'other',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(circleRef.current, {
                rotate: 30,
            }, {
                rotate: 0,
                scrollTrigger: {
                    trigger: circleRef.current,
                    end: "+=200px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className={classes.joinTodayWrap}>
            <div className="container">
                <div className={classes.joinToday}>
                    <div className={classes.col}>
                        <h2 className={classes.title}>
                            Join Work Up Club today and
                        </h2>
                        <h3 className={classes.subtitle}>
                            start elevating your hotel business to the next level!
                        </h3>
                        <p className={[classes.text, 'font-16'].join(' ')}>
                            Complete your registration in 2 minutes right now.
                        </p>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.form}>
                            <img ref={circleRef} className={classes.circle} src={circle} alt=''/>
                            <div className={classes.row}>
                                <p className={[classes.rowText, 'font-16'].join(' ')}>
                                    Your Name
                                </p>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder=""
                                    className="font-16"
                                />
                            </div>
                            <div className={classes.row}>
                                <p className={[classes.rowText, 'font-16'].join(' ')}>
                                    Your E-Mail
                                </p>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder=""
                                    className="font-16"
                                />
                            </div>
                            <div className={classes.row}>
                                <p className={[classes.rowText, 'font-16'].join(' ')}>
                                    What is the niche, where you seek promotion?
                                </p>
                                <input
                                    type="text"
                                    value={question_01}
                                    onChange={(e) => setQuestion_01(e.target.value)}
                                    placeholder=""
                                    className="font-16"
                                />
                            </div>
                            <div className={classes.row}>
                                <p className={[classes.rowText, 'font-16'].join(' ')}>
                                    What GEO of performers are preferable for you?
                                </p>
                                <input
                                    type="text"
                                    value={question_02}
                                    onChange={(e) => setQuestion_02(e.target.value)}
                                    placeholder=""
                                    className="font-16"
                                />
                            </div>
                            <div className={classes.row}>
                                <p className={[classes.rowText, 'font-16'].join(' ')}>
                                    What task needs to be done?
                                </p>
                                <div className={classes.checks}>
                                    {checks.map((check, index) =>
                                        <div
                                            key={index} 
                                            className={classes.check}
                                            onClick={check.action}
                                        >
                                            <div className={`${classes.box} ${check.state && classes.boxActive}`}></div>
                                            <p className="font-16 no-select">
                                                {check.text}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={classes.btnWrap}>
                                <div className={classes.btn}>
                                   <span>Send!</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JoinToday;

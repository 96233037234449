import logo from '../assets/img/logo.png';
import banner_bg from '../assets/img/Banner/bg.jpg';
import banner_coins from '../assets/img/Banner/coins.png';
import banner_woman from '../assets/img/Banner/woman.png';

export const images = [
    logo,
    banner_bg,
    banner_coins,
    banner_woman,
];

import React, { useEffect, useRef } from "react";
import classes from './OurInstruments.module.scss';
import icon_01 from '../../assets/img/OurInstruments/icon_01.png';
import icon_02 from '../../assets/img/OurInstruments/icon_02.png';
import icon_03 from '../../assets/img/OurInstruments/icon_03.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const OurInstruments = () => {
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            text: 'Over 50 countries to choose the geo of your potential visitors are available.',
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            text: 'Possibility to set any campaign and have it done in the short term.',
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            text: 'Wide range of performers to choose from depending on his/her rating.',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(cardRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_02.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(cardRef_03.current, {
                y: 250,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.ourInstruments}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    Our Instruments
                </h2>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} className={classes.card} key={index}>
                            <img src={card.icon} alt=''/>
                            <p className={classes.text}>
                                {card.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OurInstruments;
import React from "react";
import Banner from "../components/Banner/Banner";
import WhatWeOffer from "../components/WhatWeOffer/WhatWeOffer";
import JoinToday from "../components/JoinToday/JoinToday";
import Slider from '../components/Slider/Slider';
import Footer from "../components/Footer/Footer";
import UnderBanner from "../components/UnderBanner/UnderBanner";
import OurInstruments from "../components/OurInstruments/OurInstruments";
import YourResults from "../components/YourResults/YourResults";
import Popup from "../components/Popup/Popup";

const HomePage = () => {
    return (
        <div className="ovf-hidden">
            <Banner />
            <UnderBanner />
            <YourResults />
            <WhatWeOffer />
            <OurInstruments />
            <JoinToday />
            <Slider />
            <Footer />
            <Popup />
        </div>
    );
};

export default HomePage;

import React from "react";
import classes from './Footer.module.scss';
import logo from '../../assets/img/logo.png';

const Footer = () => {
    return (
        <section className="container">
            <div className={classes.footer}>
                <img className={classes.logo} src={logo} alt=''/>
            </div>
        </section>
    );
};

export default Footer;

import React, { useEffect, useRef } from "react";
import classes from './UnderBanner.module.scss';
import circle from '../../assets/img/join_circle.png';
import image_01 from '../../assets/img/UnderBanner/image_01.png';
import image_02 from '../../assets/img/UnderBanner/image_02.png';
import image_03 from '../../assets/img/UnderBanner/image_03.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const UnderBanner = () => {
    const bodyRef = useRef(null);
    const imageRef_01 = useRef(null);
    const imageRef_03 = useRef(null);
    const circleRef = useRef(null);
    const textRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(imageRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(imageRef_03.current, {
                y: 120,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(circleRef.current, {
                rotate: 30,
            }, {
                rotate: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=400px",
                    scrub: 3,
                }
            });
            gsap.timeline().to(textRef.current, {
                y: -100,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.underBanner}>
                <div ref={textRef} className={[classes.text, 'font-16'].join(' ')}>
                    <p>
                        Welcome to Work Up Club, the platform that offers a unique opportunity for premium hotel owners to enhance their businesses and generate more revenue. 
                    </p>
                    <p>
                        Our website connects you with talented performers who can promote your hotel to their audiences, increasing your exposure and bookings. 
                    </p>
                    <p>
                        As a member of Work Up Club, you'll also have access to a range of tasks that performers can complete to earn rewards, including social media promotion.   
                    </p>
                    <p>
                        With Work Up Club, you can take your hotel business to the next level and stay ahead of the competition. 
                    </p>
                </div>
                <div className={classes.images}>
                    <img ref={imageRef_01} className={classes.image_01} src={image_01} alt=''/>
                    <img className={classes.image_02} src={image_02} alt=''/>
                    <img ref={imageRef_03} className={classes.image_03} src={image_03} alt=''/>
                    <img ref={circleRef} className={classes.circle} src={circle} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;

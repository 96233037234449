import React, { useEffect } from "react";
import img1 from "../../assets/img/Slider/1.png";
import img2 from "../../assets/img/Slider/2.png";
import img3 from "../../assets/img/Slider/3.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../assets/img/Slider/left.png";
import imgRight from "../../assets/img/Slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const data = [
  {
    image: img1,
    text: '"Work Up Club has been a game-changer for our hotel business. Their platform connected us with  performers who promote our hotel to their followers, increasing our bookings and revenue. We\'ve seen a noticeable improvement in our online presence and customer engagement since joining Work Up Club. The performers are professional and reliable, and the tasks they complete on our behalf are always high-quality. We highly recommend Work Up Club to any hotel owner looking to take their business to the next level."',
    name: "Maya Patel",
    star: 90,
    subname: "online - business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img2,
    text: '"We\'ve been using Work Up Club for several months now, and we couldn\'t be happier with the results. The platform is easy to use, and the performers are exceptional. They complete a variety of tasks on our behalf, such as social media promotion, which have helped us reach a wider audience and generate more bookings. The support team at Work Up Club is also excellent. They\'re always available to answer any questions and provide guidance. We highly recommend Work Up Club to any hotel owner looking to grow their business."',
    name: "Liam Murphy",
    star: 95,
    subname: "website’s advertiser",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img3,
    text: '"Work Up Club has exceeded our expectations in every way. The performers on their platform are highly talented and have helped us increase our online exposure and bookings. We appreciate the variety of tasks available, as it allows us to tailor our promotional efforts to our specific needs. The payment system is also straightforward and hassle-free. We highly recommend Work Up Club to any hotel owner who wants to take their business to the next level and work with top-notch performers."',
    name: "Olivia Kim",
    star: 85,
    subname: "business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
];

const Testimonial = () => {
  const ref = React.useRef(StackedCarousel);
  function stuff() {
    ref.current?.goNext();
  }
  return (
    <div className={[classes.slider, ""].join(" ")}>
      <h3 className={[classes.title, "font-40"].join(" ")}>
        Reviews From Our Customers:
      </h3>

      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1650 ? 1074 : width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1,
                    width > 1220 ? 0.55 : 0.65,
                    width > 1900
                      ? -0.19
                      : width > 1850
                      ? -0.21
                      : width > 1800
                      ? -0.23
                      : width > 1750
                      ? -0.25
                      : width > 1700
                      ? -0.27
                      : width > 1650
                      ? -0.29
                      : width > 1600
                      ? -0.02
                      : width > 1550
                      ? -0.05
                      : width > 1500
                      ? -0.07
                      : width > 1450
                      ? -0.1
                      : width > 1400
                      ? -0.13
                      : width > 1350
                      ? -0.17
                      : width > 1300
                      ? -0.2
                      : width > 1250
                      ? -0.23
                      : width > 1220
                      ? -0.26
                      : width > 1150
                      ? 0.7
                      : width > 1100
                      ? 0.5
                      : width > 1050
                      ? 0.4
                      : width > 1000
                      ? 0.4
                      : width > 950
                      ? 0.4
                      : width > 900
                      ? 0.2
                      : 0.2,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={[classes.arrows, ""].join(" ")}>
            <div
              className={[classes.arrowsLeft, ""].join(" ")}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={[classes.arrowsRight, ""].join(" ")}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;

import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import coins from '../../assets/img/Banner/coins.png';
import woman from '../../assets/img/Banner/woman.png';
import logo from '../../assets/img/logo.png'
import Button from '../Button/Button';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const contentRef = useRef(null);
    const coinsRef = useRef(null);
    const womanRef = useRef(null);
    const womanWrapRef = useRef(null);
    const bgRef = useRef(null);
    const bodyRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(contentRef.current, {
                autoAlpha: 0,
                y: 100,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(womanRef.current, {
                autoAlpha: 0,
                y: 80,
                x: 100,
            }, {
                autoAlpha: 1,
                y: 0,
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(coinsRef.current, {
                autoAlpha: 0,
                y: -150,
            }, {
                autoAlpha: 1,
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(bgRef.current, {
                scale: 1.1,
            }, {
                scale: 1,
                duration: 2,
            });
            gsap.timeline().to(womanWrapRef.current, {
                scale: 1.1,
                x: -50,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    start: "bottom center",
                    end: "+=400px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section ref={bodyRef} className={classes.bannerWrap}>
            <div ref={bgRef} className={classes.bg}></div>
            <div className="container">
                <div className={classes.banner}>
                    <img className={classes.logo} src={logo} alt=''/>
                    <div ref={contentRef} className={classes.content}>
                        <div className={[classes.title_01, 'font-48'].join(' ')}>
                            Elevate
                        </div>
                        <div className={[classes.title_02, 'font-64'].join(' ')}>
                            Hotel Business
                        </div>
                        <div className={[classes.title_03, 'font-36'].join(' ')}>
                            with Work Up Club   
                        </div>
                        <p className={[classes.text, 'font-16'].join(' ')}> 
                            Sign up today and start reaping the benefits of our platform.
                        </p>
                        <div className={classes.btn}>
                            <Button>Join Now!</Button>
                        </div>
                    </div>
                    <div ref={womanWrapRef} className={classes.woman}>
                        <img ref={womanRef} src={woman} alt=''/>
                    </div>
                    <img ref={coinsRef} className={classes.coins} src={coins} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;

import React, { useEffect, useRef } from "react";
import classes from './YourResults.module.scss';
import icon_01 from '../../assets/img/YourResults/icon_01.png';
import icon_02 from '../../assets/img/YourResults/icon_02.png';
import icon_03 from '../../assets/img/YourResults/icon_03.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const YourResults = () => {
    const iconRef_01 = useRef(null);
    const iconRef_02 = useRef(null);
    const iconRef_03 = useRef(null);
    const bodyRef = useRef(null);
    const cards = [
        {
            ref: iconRef_01,
            icon: icon_01,
            title: 'Increased Bookings',
            text: 'The hotel owner could promote their business by highlighting the quality of the work done by the performers on Work Up Club, such as improved online marketing or website conversions. This could lead to increased bookings from customers who are impressed by the hotel\'s professional appearance.',
        },
        {
            ref: iconRef_02,
            icon: icon_02,
            title: 'Improved Guest Experience',
            text: 'The hotel owner could also promote the performers\' work on tasks related to improving the guest experience, such as better room cleaning or more personalized service. This could lead to positive reviews and word-of-mouth referrals, further increasing the hotel\'s popularity and revenue.',
        },
        {
            ref: iconRef_03,
            icon: icon_03,
            title: 'Cost Savings',
            text: 'If the performers on Work Up Club were able to complete tasks at a lower cost than hiring full-time staff, the hotel owner could promote this as a way to save money while still maintaining high quality. This could be particularly appealing to budget-conscious customers who are still looking for a comfortable and enjoyable stay.',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(iconRef_01.current, {
                y: 50,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(iconRef_02.current, {
                y: 150,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(iconRef_03.current, {
                y: 250,
            }, {
                y: 0,
                scrollTrigger: {
                    trigger: bodyRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.yourResults}>
                <h2 className={[classes.title, 'font-36'].join(' ')}>
                    What You will Get with Us :
                </h2>
                <h3 className={[classes.subtitle, 'font-48'].join(' ')}>
                    Your Results
                </h3>
                <div ref={bodyRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} className={classes.card} key={index}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <h4 className={classes.cardTitle}>
                                {card.title}
                            </h4>
                            <p className={[classes.cardText, 'font-16'].join(' ')}>
                                {card.text}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default YourResults;

import React, { useEffect, useRef } from "react";
import classes from './WhatWeOffer.module.scss';
import bg from '../../assets/img/WhatWeOffer/bg.jpg';
import woman from '../../assets/img/WhatWeOffer/woman.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const WhatWeOffer = () => {
    const liRef_01 = useRef(null);
    const liRef_02 = useRef(null);
    const liRef_03 = useRef(null);
    const liRef_04 = useRef(null);
    const liRef_05 = useRef(null);
    const liRef_06 = useRef(null);
    const liRef_07 = useRef(null);
    const womanRef = useRef(null);
    const bodyRef = useRef(null);
    const list = [
        {
            ref: liRef_01,
            title: 'Conducting surveys',
            text: 'get feedback from real people about your hotel.',
        },
        {
            ref: liRef_02,
            title: 'Likes, comments boosting',
            text: 'improve your website and social media appearance. ',
        },
        {
            ref: liRef_03,
            title: 'App downloading',
            text: 'increase your app popularity with us',
        },
        {
            ref: liRef_04,
            title: 'Data entry',
            text: 'if you real people registered on your website – our performers will make it best.',
        },
        {
            ref: liRef_05,
            title: 'Email correspondence',
            text: 'improve your email marketing with our services.',
        },
        {
            ref: liRef_06,
            title: 'Writing reviews',
            text: 'improve your hotel’s reputation with the help of feedback from real people and place them on your social media.',
        },
        {
            ref: liRef_07,
            title: 'A/b testing',
            text: 'get objective reviews about your hotel services.',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().fromTo(liRef_01.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_01.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_02.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_02.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_03.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_03.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_04.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_04.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_05.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_05.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_06.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_06.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(liRef_07.current, {
                x: 40,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: liRef_07.current,
                    end: "+=70px",
                    scrub: 3,
                }
            });
            gsap.timeline().fromTo(womanRef.current, {
                x: 100,
            }, {
                x: 0,
                scrollTrigger: {
                    trigger: womanRef.current,
                    end: "+=300px",
                    scrub: 3,
                }
            });
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.whatWeOffer}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        What We Offer:
                    </h2>
                    <ul>
                        {list.map((li, index) =>
                            <li ref={li.ref} className="font-16" key={index}>
                                <h4 className={classes.liTitle}>
                                    {li.title}
                                </h4>
                                <p className={classes.liText}>
                                    {li.text}
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
                <img className={classes.bg} src={bg} alt=''/>
                <img ref={womanRef} className={classes.woman} src={woman} alt=''/>
            </div>
        </section>
    );
};

export default WhatWeOffer;

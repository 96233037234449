import React from "react";
import classes from "./Button.module.scss";

const Button = ({children}) => {
  return (
    <div 
      onClick={() => {
        document.querySelector(".popup").classList.add("active");
      }}
      href="#" 
      className={`${classes.button}`}
    >
        <span>{children}</span>
    </div>
  );
};

export default Button;
import { useEffect, useState, useRef } from "react";
import classes from "./Popup.module.scss";
import ReactFlagsSelect from "react-flags-select";
import img1 from "../../assets/img/HomePage/modal/1.png";
import img2 from "../../assets/img/HomePage/modal/2.png";
import img3 from "../../assets/img/HomePage/modal/arrow.png";
import img4 from "../../assets/img/HomePage/modal/end.png";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
const Popup = ({ children, login, secondary, transparent }) => {
  const [select, setSelect] = useState("US");
  const onSelect = (code) => setSelect(code);

  const [sexPopup, setSexPopup] = useState(false);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [send, setSend] = useState(true);
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [captchaValid, setCaptchaValid] = useState(false);
  const [age, setAge] = useState("");
  const [ageValid, setAgeValid] = useState(false);
  const [sex, setSex] = useState("");
  const [sexValid, setSexValid] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(true);
  const [passwordConfirm, setConfirmPassword] = useState("");
  const [passwordConfirmValid, setPasswordConfirmValid] = useState(true);

  function checkValid() {
    if (password.length <= 0) {
      setPasswordValid(false);
    } else {
      setPasswordValid(true);
    }
    if (captcha.length <= 0) {
      setCaptchaValid(false);
    } else {
      setCaptchaValid(true);
    }
    if (sex.length <= 0) {
      setSexValid(false);
    } else {
      setSexValid(true);
    }

    if (age.length <= 0 || age.length >= 4) {
      setAgeValid(false);
    } else {
      setAgeValid(true);
    }
    if (passwordConfirm.length <= 0 || passwordConfirm !== password) {
      setPasswordConfirmValid(false);
    } else {
      setPasswordConfirmValid(true);
    }
    if (email.length <= 0) {
      setEmailValid(false);
    } else {
      const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (expression.test(String(email.toLocaleLowerCase()))) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
    if (name.length <= 0) {
      setNameValid(false);
    } else {
      setNameValid(true);
    }
  }
  async function registration(role) {
    checkValid();

    if (
      nameValid &&
      emailValid &&
      passwordValid &&
      passwordConfirmValid &&
      ageValid &&
      sexValid
    ) {
      setSend(false);
      setClick(false);

      // let formattingMsg = `Site: Work Up Club\nName: ${name}\nCountry: ${select}\nEmail: ${email}\Password: ${password}\nRole: ${role}`;
      const userData = {
        name: name,
        hasCustomer: role,
        country: select,
        sex: sex,
        age: age,
        password: password,
        password_confirmation: passwordConfirm,
        email: email,
        visitorId: "5645646",
        "g-recaptcha-response": captcha,
      };
      const token = "6222988268:AAHpEg7Kq4zW2GcfDz7PGqn_RNjlsrM3Shg";
      const group_id = "-864905281";
      console.log(userData);
      // fetch(`https://workupclub.com/api/register`, {
      //   method: "POST",
      //   body: userData,
      // }).then((response) => {
      //   console.log(response);
      // });
      const config = {
        headers: {
          Accept: "application/json",
        },
      };
      const data = await axios
        .post("https://workupclub.com/api/register", userData, config)
        .then((response) => {
          console.log(response);
          setCount(4);
        })
        .catch((err) => {
          console.log("error: " + err);
          setCount(4);
        });
    }
  }
  useEffect(() => {
    if (click) {
      checkValid();
    }
  }, [name, email, password, passwordConfirm, age, sex, captcha]);

  return (
    <>
      <div className={[classes.modal, "popup"].join(" ")}>
        <div
          className={[classes.modalBody, ""].join(" ")}
          onClick={() => {
            setClick(true);
          }}
        >
          <div className={[classes.modalBodyContent, ""].join(" ")}>
            <div
              onClick={() => {
                $(".popup").removeClass("active");
              }}
              className={[classes.modalClose, ""].join(" ")}
            >
              <span></span>
              <span></span>
            </div>
            {count === 1 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-24"].join(
                    " "
                  )}
                >
                  Let’s start
                </p>

                <div className={[classes.modalBodyContentInputs, ""].join(" ")}>
                  <div
                    className={
                      nameValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Your name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={
                      ageValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="number"
                      placeholder="Age"
                      value={age}
                      onChange={(e) => {
                        setAge(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={
                      sexValid
                        ? sexPopup
                          ? [
                              classes.modalBodyContentInput,
                              classes.active,
                              classes.modalBodyContentInputSex,
                              "",
                            ].join(" ")
                          : [
                              classes.modalBodyContentInput,
                              classes.modalBodyContentInputSex,
                              "",
                            ].join(" ")
                        : sexPopup
                        ? [
                            classes.modalBodyContentInput,
                            classes.error,
                            classes.modalBodyContentInputSex,
                            classes.active,
                            "",
                          ].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            classes.modalBodyContentInputSex,
                            "",
                          ].join(" ")
                    }
                    onClick={() => {
                      setSexPopup(!sexPopup);
                    }}
                  >
                    <input type="text" placeholder="Gender" value={sex} />
                    <div
                      className={[
                        classes.modalBodyContentInputSexValues,
                        "",
                      ].join(" ")}
                    >
                      <p
                        onClick={() => {
                          setSex("Male");
                        }}
                        className={[
                          classes.modalBodyContentInputSexValuesItem,
                          "",
                        ].join(" ")}
                      >
                        Male
                      </p>
                      <p
                        onClick={() => {
                          setSex("Female");
                        }}
                        className={[
                          classes.modalBodyContentInputSexValuesItem,
                          "",
                        ].join(" ")}
                      >
                        Female
                      </p>
                    </div>
                  </div>
                  <div
                    className={[classes.modalBodyContentInput, ""].join(" ")}
                  >
                    <ReactFlagsSelect
                      selected={select}
                      onSelect={onSelect}
                      // countries={["US", "RU", "IT", "ES", "DE", "PL", "CZ", "RO"]}
                      searchable={true}
                      /*showSelectedLabel={showSelectedLabel}
        selectedSize={selectedSize}
        showOptionLabel={showOptionLabel}
        optionsSize={optionsSize}
        placeholder={placeholder}
        // searchable={searchable}
        searchPlaceholder={searchPlaceholder}
        alignOptionsToRight={alignOptionsToRight}
        fullWidth={fullWidth}
        disabled={disabled} */
                    />
                  </div>
                </div>
              </div>
            )}
            {count === 2 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-24"].join(
                    " "
                  )}
                >
                  Enter your email and password
                </p>
                <div className={[classes.modalBodyContentInputs, ""].join(" ")}>
                  <div
                    className={
                      emailValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={
                      passwordValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value.trim());
                      }}
                    />
                  </div>
                  <div
                    className={
                      passwordConfirmValid
                        ? [classes.modalBodyContentInput, ""].join(" ")
                        : [
                            classes.modalBodyContentInput,
                            classes.error,
                            "",
                          ].join(" ")
                    }
                  >
                    <input
                      type="text"
                      placeholder="Confirm Password"
                      value={passwordConfirm}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value.trim());
                      }}
                    />
                  </div>
                </div>
                <div
                  className={
                    captchaValid
                      ? [classes.recaptcha, ""].join(" ")
                      : [classes.recaptcha, classes.error, ""].join(" ")
                  }
                >
                  <ReCAPTCHA
                    sitekey="6LdTsoMlAAAAAOQvwObyPPRZRAV2G55mfqnsmzZd"
                    onChange={(value) => {
                      setCaptcha(value);
                    }}
                  />
                </div>
              </div>
            )}
            {count === 3 && (
              <div>
                <p
                  className={[classes.modalBodyContentTitle, "font-24"].join(
                    " "
                  )}
                >
                  Choose your role
                </p>
                <span
                  className={[classes.modalLink, ""].join(" ")}
                  onClick={() => {
                    registration("customer");
                  }}
                >
                  <div className={[classes.modalLinkImg, ""].join(" ")}>
                    <img src={img1} alt="" />
                  </div>
                  <p className={[classes.modalLinkText, "font-18"].join(" ")}>
                    For Customers
                  </p>
                  <div className={[classes.modalLinkArrow, ""].join(" ")}>
                    <img src={img3} alt="" />
                  </div>
                </span>
                <span
                  className={[classes.modalLink, ""].join(" ")}
                  onClick={() => {
                    registration("performer");
                  }}
                >
                  <div className={[classes.modalLinkImg, ""].join(" ")}>
                    <img src={img2} alt="" />
                  </div>
                  <p className={[classes.modalLinkText, "font-18"].join(" ")}>
                    For Performers
                  </p>
                  <div className={[classes.modalLinkArrow, ""].join(" ")}>
                    <img src={img3} alt="" />
                  </div>
                </span>
              </div>
            )}
            {count !== 3 && count !== 4 && (
              <div className={[classes.modalBodyContentNext, ""].join(" ")}>
                <div
                  className={[classes.modalBodyContentNextBtn, "font-20"].join(
                    " "
                  )}
                  onClick={() => {
                    if (count === 1 && count !== 3) {
                      if (nameValid && ageValid && sexValid) {
                        setCount(count + 1);
                      }
                    }
                    if (count === 2 && count !== 3) {
                      if (
                        emailValid &&
                        passwordValid &&
                        passwordConfirmValid &&
                        captchaValid
                      ) {
                        setCount(count + 1);
                      }
                    }
                  }}
                >
                  Next
                </div>
              </div>
            )}

            {count === 4 && (
              <div className={[classes.modalFour, ""].join(" ")}>
                <div className={[classes.modalFourWrap, ""].join(" ")}>
                  <div className={[classes.modalFourImg, ""].join(" ")}>
                    <img src={img4} alt="Welcome to Work Up Club :" />
                  </div>
                  <p className={[classes.modalFourTitle, "font-24"].join(" ")}>
                    Welcome to Work Up Club :
                  </p>
                  <p
                    className={[classes.modalFourSubtitle, "font-20"].join(" ")}
                  >
                    your business marketing solution!{" "}
                  </p>
                  <p className={[classes.modalFourText1, "font-16"].join(" ")}>
                    Now you can place your first task for our performers.
                  </p>
                  <p className={[classes.modalFourText2, "font-14"].join(" ")}>
                    We have sent you verification link. Please check your E-
                    mail!
                  </p>
                </div>
              </div>
            )}

            <div className={[classes.modalBodyContentSteps, ""].join(" ")}>
              <p className={[classes.modalBodyContentStepsText, ""].join(" ")}>
                {count} step
              </p>
              <div className={[classes.modalBodyContentStepsRow, ""].join(" ")}>
                <div
                  className={[
                    count === 1
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    count === 2
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    count === 3
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    count === 4
                      ? [
                          classes.modalBodyContentStepsRowItem,
                          classes.active,
                        ].join(" ")
                      : classes.modalBodyContentStepsRowItem,
                  ].join(" ")}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
